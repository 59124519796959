import { VERIFICATION_LOGINN } from "../constant/ActionType";

export default function AllVerifyLoginPassRedc(
  state = {
    alllogindatas: [],
    statuscode: null,
  },
  action
) {
  switch (action.type) {
    case VERIFICATION_LOGINN:
      return {
        ...state,
        alllogindatas: action?.data,
        lastPage: action?.data?.data?.last_page,
      };

    default:
  }

  return state;
}
