// third-party
import { combineReducers } from "redux";

// project-imports
import menu from "./menu";
import RegisterOwnerBrandReducer from "./registerbrandowner";
import registerBrandUser from "./registerbranduser";
import CreateRolssseeeReducer from "./createrole";
import ViewAllRolesReduncerre from "./viewallrole";
import AssignPermisionsReducer from "./assignpermissions";
import GetAllPermissionReducer from "./getallassignpermissions";
import ViewAllRedUsers from "./viewAllUsers";
import EditUserDataReducer from "./edituser";
import ViewallBrandssRedUsers from "./viewallbrand";
import BrandDetailsGetDataReducer from "./detailbrandedit";
import AllGetCateeReducers from "./allcategeory";
import SubAllCateReducers from "./allsubcategries";
import AllCompaniesReducers from "./allcompany";
import AllCountReducers from "./allcount";
import CompanyDetailingReducer from "./companydetails";
import AllProducts from "./allProducts";
import AllActiveCompanies from "./AllActiveCompanies";
import AllRandomCode from "./allRandom";
import AllActiveBrands from "./allActiveBrands";
import AllActiveProducts from "./allActiveProducts";
import GetRolePermissions from "./rolePermissions";
import AllBatchesReducers from "./allBatches";
import AllSmsLogs from "./allsmslog";
import Reports from "./reports";
import SMSReply from "./smsreply";
import ColorShift from "./ColorShift";
import DomainParameter from "./DominParameter";
import ExportReducerCsv from "./exportcsv";
import CSvCompanyReducer from "./csvcompany";
import CSVCategories from "./exportcsvcategory";
import AllCsvRadmon from "./exportcsvrandomcode";
import CSvQRReducer from "./csvQRcode";
import ReportProblemReducer from "./ReportProblemReducer";
import ProgressReducer from "./ProgressCode";
import MapDataReducers from "./mapdata";
import PostPreDataReduncers from "./prepostdataredd";
import AllVerifyLoginPassRedc from "./verylogindatare";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  RegisterOwnerBrandReducer,
  registerBrandUser,
  CreateRolssseeeReducer,
  ViewAllRolesReduncerre,
  AssignPermisionsReducer,
  Reports,
  GetAllPermissionReducer,
  ViewAllRedUsers,
  AllBatchesReducers,
  EditUserDataReducer,
  ViewallBrandssRedUsers,
  BrandDetailsGetDataReducer,
  AllGetCateeReducers,
  SubAllCateReducers,
  AllCompaniesReducers,
  AllCountReducers,
  CompanyDetailingReducer,
  AllActiveCompanies,
  AllProducts,
  AllRandomCode,
  AllActiveBrands,
  AllActiveProducts,
  GetRolePermissions,
  AllSmsLogs,
  SMSReply,
  ColorShift,
  DomainParameter,
  ExportReducerCsv,
  CSvCompanyReducer,
  CSVCategories,
  AllCsvRadmon,
  CSvQRReducer,
  ReportProblemReducer,
  ProgressReducer,
  MapDataReducers,
  PostPreDataReduncers,
  AllVerifyLoginPassRedc,
});

export default reducers;
